<template>
  <v-app>
    <v-container>
      <v-card>
        <v-card-title>Качество</v-card-title>
        <v-card-actions class="mb-2">
          <v-btn v-for="(_tab) in tabs"
                 :key="_tab.type"
                 @click="openTab(_tab.type)"
                 :class="tab===_tab.type ? 'v-btn-info' : 'v-btn-transp'">
            {{ _tab.title }}
            <v-badge style="margin-bottom: 20px" v-if="_tab.type===0 && $store.state.updatingInfo.feedback"
                     color="orange"
                     :content="$store.state.updatingInfo.feedback" class="custom-badge"/>
            <v-badge style="margin-bottom: 20px" v-if="_tab.type===2 && $store.state.updatingInfo.feedback_by_client"
                     color="red"
                     :content="$store.state.updatingInfo.feedback_by_client" class="custom-badge"/>
            <v-badge style="margin-bottom: 20px" v-if="_tab.type===4 && $store.state.updatingInfo.conflict_in_progress"
                     color="red"
                     :content="$store.state.updatingInfo.conflict_in_progress" class="custom-badge"/>
            <v-badge style="margin-bottom: 20px" v-if="_tab.type===3 && $store.state.updatingInfo.feedback5"
                     color="green"
                     :content="$store.state.updatingInfo.feedback5" class="custom-badge"/>
          </v-btn>
        </v-card-actions>
        <v-card-actions v-if="tab === 2" class="d-block">
          <v-row class="mb-2">
            <YearMonth :value="[date.year, date.month]" @change="onChangeDate"/>
          </v-row>
          <v-row>
            <v-tooltip top v-for="(stat, key) in order_stats" :key="key">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                       <v-chip color="#fff" class="mr-2" label>
              <v-icon size="20" style="margin-right: 5px" color="green">mdi-{{ $store.state.order_stats_obj[key].icon }}</v-icon>
              {{ $root.printCost(stat.sum) }} ({{ stat.count || 0 }}шт.)
            </v-chip>
                </span>
              </template>
              <span>{{$store.state.order_stats_obj[key].tip}}</span>
            </v-tooltip>
            <!--           без конфликтов
                           сумма/кол-во возвратов
                           сумма/кол-во бонусов
                           сумма/кол-во компенсаций
                           кол-во переуборок (на какую сумму)-->
          </v-row>
        </v-card-actions>
        <v-card-text>
          <v-data-table
              hover
              :items="rows"
              :headers="currentTableHeader"
              item-key="id"
              :loading="loading"
              :footer-props="tab===3?{'items-per-page-options':[100]}:{'items-per-page-options':[-1]}"
              no-data-text="Список пуст"
              loading-text="Загрузка..."
              mobile-breakpoint="0">
            <template v-slot:item="{item, index}">
              <tr v-if="tab===3 && !item.cleaners">
                <td>{{ index + 1 }}</td>
                <td><a target="_blank" :href="'#/orders/'+item.order_id">Заказ №{{ item.order_id }}</a></td>
                <td>
                  <div style="max-width: 300px;background: #eee;padding: 5px 10px;margin: 10px 0;border-radius: 10px">
                    {{ item.comment }}
                  </div>
                </td>
                <td>
                  <v-radio-group @change="updatePublished(item.n_id, item.published)" v-model="item.published">
                    <v-radio
                        label="Да"
                        value="1"
                    ></v-radio>
                    <v-radio
                        label="Нет"
                        value="2"
                    ></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr v-else style="cursor: pointer" @click="editReview(item)">
                <td>
                  <v-tooltip right
                             v-if="item.feedback.by_client*1 && ($store.state.user.city.id==1 ? [1,2,3,4]:[1,2,3]).includes(item.feedback.rate*1) && !item.feedback.comment_manager">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" color="red">mdi-alert</v-icon>
                    </template>
                    Клиент поставил низкую оценку, надо выяснить почему и заполнить поле "комментарий менеджера"
                  </v-tooltip>
                  {{ item.client.name }}
                </td>
                <td v-on:click.stop><a :href="'tel: ' + item.client.phone"
                                       style="white-space: nowrap">{{ item.client.phone }}</a></td>
                <td v-on:click.stop><a target="_blank"
                                       :href="'#/orders/'+item.n_id">{{ $root.dateToRus(item.datetimecleanObj.date) }}
                  {{ item.datetimecleanObj.time }}</a></td>
                <td v-if="[2, 4].includes(tab)">
                  <div v-if="+item.feedback.datetime_conflict_start" class="py-2 text-center">
                    Начало:
                    {{ $moment(item.feedback.datetime_conflict_startObj.date).format('DD.MM.YY') }}, {{item.feedback.datetime_conflict_startObj.time}}
                    ({{ $store.state.user.feedbackResolveOptions[item.feedback.resolve_type]}})
                    <v-chip v-if="item.feedback.conflict_duration > 4"
                            class="mt-2"
                            color="red" text-color="#FFF">
                      <template>Конфликт длится более 4 дней</template>
                    </v-chip>
                  </div>
                </td>
                <td v-if="[2, 4].includes(tab)">
                  {{item.feedback.comment_manager}}
                </td>
                <td style="white-space: nowrap">{{ $root.printCost(item.total) }}</td>
                <td v-on:click.stop v-if="tab !== 4">
                  <a :href="`#/orders/${order.n_id}`" target="_blank" v-for="(order, index) in item.prevOrders"
                     class="mr-2 my-1" style="display: inline-block" :key="index">
                    <v-chip color="#eee" style="cursor: pointer">
                      <template v-if="order.feedback">
                        <IconTip v-if="order.feedback.by_client*1" position="right" icon="account-check" color="blue"
                                 title="Оценку поставил клиент самостоятельно"/>
                        {{ order.feedback.rate }}
                        <v-icon color="amber" size="16">mdi-star</v-icon>
                      </template>
                      <template v-else>Нет оценки</template>
                      <IconTip position="top" v-if="order.mobile_app" title="Оформлен через приложение" icon="cellphone"
                               color="green"/>
                    </v-chip>
                  </a>
                </td>
                <td v-if="tab !== 4">
                  <IconTip v-if="+item.client.pin" title="Есть приложение" icon="check" color="green"/>
                  <IconTip v-else title="Нет приложения" icon="close" position="top" color="red"/>
                </td>
                <td v-if="tab !== 4">
                  <IconTip v-if="item.hasHousemap" title="Есть карта дома" icon="check" color="green"/>
                  <IconTip v-else title="Нет карты дома" icon="close" position="top" color="red"/>
                </td>
                <td v-if="tab !== 4">
                  <IconTip v-if="item.hasRefOrders"
                           :title="['', 'Есть только отмененный заказ от друга', 'Есть только неоплаченный заказ от друга', 'Есть оплаченный заказ от друга'][+item.hasRefOrders]"
                           icon="check" :color="['', 'red', 'blue', 'green'][+item.hasRefOrders]"/>
                  <IconTip v-else title="Нет заказов от друзей" icon="close" position="top" color="red"/>
                </td>
                <td>
                  <IconTip v-if="item.feedback.by_client*1" position="right" icon="account-check"
                           :color="+item.feedback.rate_changed?'red':'blue'"
                           title="Оценку поставил клиент самостоятельно"/>
                  <v-rating
                      v-if="item.feedback.rate"
                      :value="parseInt(item.feedback.rate) || 0"
                      color="amber"
                      dense
                      readonly
                      size="14"
                  ></v-rating>
                </td>
                <td>
                  <IconTip v-if="item.feedback.by_client*1" position="right" icon="account-check"
                           :color="+item.feedback.rate_manager_changed?'red':'blue'"
                           title="Оценку поставил клиент самостоятельно"/>
                  <v-rating
                      v-if="+item.feedback.rate_manager"
                      :value="parseInt(item.feedback.rate_manager) || 0"
                      color="amber"
                      dense
                      readonly
                      size="14"
                  ></v-rating>
                </td>
                <td v-if="tab !== 4">
                  <CleanerLink v-for="cleaner in item.cleaners" :key="cleaner.n_id" :value="cleaner"/>
                </td>
                <td>
                  {{ $root.managerName(item.user_id) }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <Review ref="review"/>
  </v-app>

</template>

<script>
import Review from "@/views/orders/modals/Review";
import CleanerLink from "../../components/CleanerLink";
import IconTip from "../../components/IconTip";
import DatePeriod from "../../components/DatePeriod";
import YearMonth from "@/components/YearMonth";

export default {
  name: "Table",
  components: {
    YearMonth,
    DatePeriod,
    IconTip,
    CleanerLink,
    Review,
  },
  data() {
    return {
      tabs: [
        {type: 0, title: 'Без оценки', value: 'normal'},
        {type: 1, title: 'Недозвон', value: 'normal'},
        {type: 4, title: 'В процессе урегулирования', value: 'conflict'},
        {type: 2, title: 'Архив', value: 'archive'},
        {type: 3, title: 'На публикацию', value: 'publication'},
      ],
      tab: 0,
      loading: true,
      tableHeaders: {
        normal: [
          {text: 'Имя', value: 'client'},
          {text: 'Телефон', value: 'client'},
          {text: 'Дата уборки', value: 'datetimecleanObj'},
          {text: 'Сумма', value: 'total'},
          {text: 'Прошлые заказы', value: 'prevOrders'},
          {text: 'Приложение', value: 'client.pin'},
          {text: 'Карта дома', value: 'hasHousemap'},
          {text: 'Приведи друга', value: 'hasRefOrders'},
          {text: 'Оценка', value: 'feedback.rate'},
          {text: 'Оценка менеджера', value: 'feedback.rate_manager'},
          {text: 'Клинеры', value: 'cleaners'},
          {text: 'Менеджер', value: 'manager'},
        ],
        publication: [
          {text: '№', value: 'index'},
          {text: 'Заказ', value: 'order'},
          {text: 'Коммент', value: 'comment'},
          {text: 'Опубликовать', value: 'published'}
        ],
        conflict: [
          {text: 'Имя', value: 'client'},
          {text: 'Телефон', value: 'client'},
          {text: 'Дата уборки', value: 'datetimecleanObj'},
          {text: 'Конфликт', value: 'datetime_conflict_start'},
          {text: 'Коммент менеджера', value: 'feedback.comment_manager'},
          {text: 'Сумма', value: 'total'},
          {text: 'Оценка', value: 'feedback.rate'},
          {text: 'Оценка менеджера', value: 'feedback.rate_manager'},
          {text: 'Менеджер', value: 'manager'},
        ],
        archive: [
          {text: 'Имя', value: 'client'},
          {text: 'Телефон', value: 'client'},
          {text: 'Дата уборки', value: 'datetimecleanObj'},
          {text: 'Конфликт', value: 'datetime_conflict_start'},
          {text: 'Коммент менеджера', value: 'feedback.comment_manager'},
          {text: 'Сумма', value: 'total'},
          {text: 'Прошлые заказы', value: 'prevOrders'},
          {text: 'Приложение', value: 'client.pin'},
          {text: 'Карта дома', value: 'hasHousemap'},
          {text: 'Приведи друга', value: 'hasRefOrders'},
          {text: 'Оценка', value: 'feedback.rate'},
          {text: 'Оценка менеджера', value: 'feedback.rate_manager'},
          {text: 'Клинеры', value: 'cleaners'},
          {text: 'Менеджер', value: 'manager'},
        ]
      },
      rows: [],
      date: {
        year: '',
        month: ''
      },
      order_stats: {},
    }
  },
  computed: {
    currentTableHeader() {
      let currentTab = this.tabs.find(tab => tab.type === this.tab);
      return this.tableHeaders[currentTab.value];
    }
  },
  methods: {
    getFeedbacks(repeat = false) {
      if (!repeat) {
        this.rows = [];
        this.loading = true;
      } else if (this.tab === 3) return setTimeout(() => this.getFeedbacks(true), 5000);
      this.$store.state.server.request('order/feedback', {
        type: this.tab,
        year: this.date.year,
        month: this.date.month
        // date: this.archive && 0 ? this.date : null
      }, (data) => {
        if (data.stats) {
          this.order_stats = data.stats;
        }
        this.rows = data.response;
        this.loading = false;
        if (repeat) setTimeout(() => {
          if (this.$router.currentRoute.path === '/feedback') this.getFeedbacks(true)
        }, 5000)
      }, () => {
        this.loading = false;
        if (repeat) setTimeout(() => {
          if (this.$router.currentRoute.path === '/feedback') this.getFeedbacks(true)
        }, 5000)
      })
    },
    editReview(order) {
      const modal = this.$refs.review;
      modal.open(order)
    },
    openTab(tab) {
      this.tab = tab;
      this.$router.push({query: {tab: tab}});
      if (this.tab === 2) {
        this.date.year = this.$moment().format('YYYY');
        this.date.month = this.$moment().format('MM');
      } else {
        this.date.year = '';
        this.date.month = '';
      }
      this.getFeedbacks()
    },
    updatePublished(id, value) {
      this.$store.state.server.request('order/updateFeedback/' + id, {published: value}, () => {
        this.$root.notify(value == 1 ? 'Отзыв будет опубликован' : 'Отзыв не будет опубликован');
      });
    },
    onChangeDate(date) {
      this.date.year = date[0];
      this.date.month = date[1];
      this.getFeedbacks();
    }
  },
  mounted() {
    if (+this.$route.query.tab) {
      this.tab = +this.$route.query.tab;
      if (this.tab === 2) {
        this.date.year = this.$moment().format('YYYY');
        this.date.month = this.$moment().format('MM');
      }
    }
    this.getFeedbacks(this.tab !== 3);
    this.$eventBus.$on('feedbackUpdated', () => {
      this.getFeedbacks();
    });
  },
  destroyed() {
    this.$eventBus.$off('feedbackUpdated');
  }
}
</script>

<style scoped>

</style>
